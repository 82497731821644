import { AbstractControl, ValidationErrors } from '@angular/forms';

export function JsonValidator(control: AbstractControl): ValidationErrors {
  return !control.value || isValidJson(control.value) ? null : { json: true };
}

function isValidJson(value: string): boolean {
  if (value === undefined || value === null || value === '') {
    return true;
  }
  try {
    JSON.parse(value);
  } catch {
    return false;
  }
  return true;
}
